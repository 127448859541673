<template>
  <v-select
      label="label"
      :options="eps_list"
      :reduce="(eps) => (mode === 'object' ? eps : eps.id)"
      @search="debounceSearch"
      @input="setChange"
      :class="classObject"
      v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
          :class="classObject"
      />
    </template>
  </v-select>
</template>
<script>
import ServiceFactory from "../../services/ServiceFactory";
import VSelect from 'vue-select';
import {isEmpty, debounce} from 'lodash';

const EpsService = ServiceFactory.get("eps");

export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      selected : this.value,
      eps_list: [],

    };
  },
  created() {
    this.debounceSearch = debounce(this.search, 300);
  },
  watch : {
    value : {
      handler : async function(value){

        if(this.mode === 'object'){
          if(isEmpty(value) || value === undefined || value === null){
            this.selected = this.mode === 'object' ? {} : '';
            return;
          }
        }else{
          if(value === '' || value === undefined || value === null){
            this.selected = this.mode === 'object' ? {} : '';
            return;
          }
        }

        let id = this.mode === 'object' ? value.id : value;

        if(isEmpty(this.eps_list.find(x => x.id === id))){

          let response = await EpsService.get(id);

          this.eps_list.push({
            label : `${response.data.IDEPS} - ${response.data.DESEPS}`,
            id : response.data.id,
            contratos : response.data?.contratos
          });
        }

        this.selected = value;
        this.$emit('input', value);
      },
      deep : true,
    }
  },
  methods: {
    async search(search, loading) {

      if(search === '')
      {
        return;
      }

      loading(true);

      let response = await EpsService.search(search);
      this.eps_list = response.data;

      loading(false);
    },
    setChange(value){
      this.$emit('update-value', value);
      this.$emit('input', value);
    }
  },
  computed : {
    classObject : function (){

      if(this.validator === undefined || this.validator === null){
        return '';
      }

      return this.validator.$error ? 'v-select-error is-invalid' : 'is-valid';
    }
  }
};
</script>
<style scoped>
</style>
